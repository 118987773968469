<template>
  <div>
    <div class="contained-example-container" v-if="!checkData">
      <div id="div-with-loading" class="con-vs-loading __container mt-6">
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
    <div v-if="checkData">
      <vx-card title="🏸⚡Buscador Avanzado" title-color="black">
        <p
          class="mb-1"
        >Encuentre opciones especificas para el cliente, utilizando los filtros para su búsqueda.</p>
        <vs-alert
          color="success"
          active="true"
          icon-pack="feather"
          icon="icon-layers"
          class="mt-4 mb-2"
        >
          Apartamentos disponibles:
          <strong v-if="mostrarDisponibles">{{mostrarDisponibles.length}} unidades</strong>
        </vs-alert>
      </vx-card>
      <br />

      <vs-row class="flex" v-if="!(rowSelectionID === null)">
        <vs-button :to="{ name: 'Apartamento' , params:{Id: `${selectedApartament}`}}" color="primary" icon-pack="feather" icon="icon-eye" class="mb-4 m-1">Ver Unidad</vs-button>
        <vs-button :to="{ name: 'Generar Cotización'}" color="success" icon-pack="feather" icon="icon-file" class="mb-4 m-1">Cotizar Unidad</vs-button>
        <vs-button  @click="sendToCompare(rowSelectionID)" color="success" icon-pack="feather" icon="icon-copy" class="mb-4 m-1">Comparar</vs-button>
      </vs-row>
      <vx-card>
        <!-- TABLE ACTION ROW -->
        <div class="flex flex-wrap justify-between items-center">
          <!-- ITEMS PER PAGE -->
          <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left"></div>

          <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
        </div>
        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-2 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="mostrarDisponibles"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="true"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          @selection-changed="onSelectionChanged"
          :rowDeselection="true"
        ></ag-grid-vue>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import gql from "graphql-tag";
import router from '../../router'

// import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";

export default {
  components: {
    AgGridVue
  },
  data() {
    return {
      rowSelectionID: null,
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 12,
      selectedApartament: '',
      gridApi: null,
      getDeveloper: [],
      defaultColDef: {
        sortable: true,
        editable: true,
        resizable: false,
        suppressMenu: false
      },
      columnDefs: [
        {
          headerName: "Proyecto",
          field: "proyect_name",
          width: 265,
          filter: true,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: false,
          headerCheckboxSelection: true
        },
        {
          headerName: "Apto.",
          field: "number",
          filter: true,
          width: 110
        },
        {
          headerName: "Nivel",
          field: "level",
          filter: true,
          width: 110
        },
        {
          headerName: "Hab.",
          field: "bedrooms",
          filter: true,
          width: 100
        },
        {
          headerName: "Baños",
          field: "bathrooms",
          filter: true,
          width: 120
        },

        {
          headerName: "Costo",
          field: "price",
          style: "currency",
          filter: true,
          width: 150
        },
        {
          headerName: "Tipo",
          field: "apt_type",
          filter: true,
          width: 120
        },
        {
          headerName: "Jardín",
          field: "garden",
          filter: true,
          width: 120
        }
      ]
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    }
  },
  computed: {
    checkData() {
      if (this.getDeveloper == undefined) {
        return false;
      } else {
        return true;
      }
    },
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 50;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    mostrarDisponibles() {
      let apartamentosDisponibles;
      if (this.getDeveloper.apartaments) {
        apartamentosDisponibles = this.getDeveloper.apartaments.filter(
          item => item.actual_state == "Disponible"
        );
      }

      return apartamentosDisponibles;
    }
  },
  methods: {
    sendToCompare(data) {
      this.$store.dispatch("enviar_a_comparador_unidad", data);
      this.$vs.notify({
          time:8000,
          title:`Unidad enviada al comparador.`,
          text:'☝ Puede visitar el comparador pulsando aquí.',
          color:'success',
          iconPack: 'feather',
          icon:'icon-copy',
          click:()=>{
            router.push('/comparador')
          }
      })
    },
    onSelectionChanged(){
      const selectedRows = this.gridApi.getSelectedRows();
      this.rowSelectionID = selectedRows[0]
      this.selectedApartament = selectedRows[0]._id
      localStorage.proyectID = selectedRows[0].proyect_id
      localStorage.apartamentID = selectedRows[0]._id
      localStorage.apartamentNumber = selectedRows[0].number
    },
    openLoadingAdvanceSearch() {
      this.$vs.loading();
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    }
  },
  apollo: {
    getDeveloper: {
      query: gql`
        query getDeveloperData($id: ID!) {
          getDeveloper(id: $id) {
            _id
            apartaments {
              proyect_id
              _id
              plane_img
              loundry
              closets
              kitchen_furniture
              kitchen_appliances
              actual_state
              living_square_mts
              bedrooms
              bathrooms
              balcony
              garden
              number
              price
              level
              apt_type
              proyect_name
            }
          }
        }
      `,
      variables() {
        return {
          id: localStorage.company_id
        };
      },
      pollInterval: 750
    }
  },
  mounted() {
    this.$store.dispatch("autoLogin");
    this.openLoadingAdvanceSearch();
    setTimeout(() => {
      this.$vs.loading.close();
      this.gridApi = this.gridOptions.api;
    }, 2000);
    // const el = document.querySelector('.ag-body-viewport');
    // console.log(this.$refs.agGridTablePs)
    // this.$refs.agGridTablePs.__init(el)
    // this.$refs.agGridTablePs.update()
  }
};
</script>
